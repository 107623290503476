import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
    InstantSearch,
    Configure,
    connectMenu,
    connectStateResults,
    connectHits,
    connectHitInsights,
} from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import find from 'lodash/find'

import Layout from '../components/layout'

import Header from '../components/content/header'
import * as hitComps from '../components/search/hit-comps'
import Divider from '../components/content/divider'
import Categories from '../components/search/categories'
import SearchBox from '../components/search/search-box'

export const query = graphql`
    {
        allContentfulPostalCode {
            edges {
                node {
                    postalCode
                    locationGeo {
                        lat
                        lon
                    }
                }
            }
        }
    }
`

const IndexPage = ({ data }) => {
    const [latLng, setLatLng] = useState(null)
    const [location, setLocation] = useState(null)
    const [currentPostalCode, setCurrentPostalCode] = useState('')

    const setPostalCode = (postalCode) => {
        const allPostalCodes = data.allContentfulPostalCode.edges
        const postalCodeEntry = find(allPostalCodes, {
            node: {
                postalCode: `${postalCode}`,
            },
        })

        if (typeof postalCodeEntry !== 'undefined') {
            const latLng = `${postalCodeEntry.node.locationGeo.lat}, ${postalCodeEntry.node.locationGeo.lon}`
            setLatLng(latLng)
            setLocation(postalCode)
            setCurrentPostalCode(postalCode)
        } else {
            setLatLng(null)
            setLocation(null)
            setCurrentPostalCode(null)
        }
    }

    const searchIndices = {
        default: {
            name: `prod_Providers`,
            title: `Providers`,
            hitComp: `SearchHit`,
            helpText: 'Search',
        },
        Providers: {
            name: `prod_Providers`,
            title: `Providers`,
            hitComp: `SearchHit`,
            helpText: 'Search',
        },
    }

    const selectedIndex = searchIndices.default

    const searchClient = algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
    )

    const indexName = selectedIndex.name
    const hitComp = selectedIndex.hitComp

    return (
        <Layout>
            <InstantSearch searchClient={searchClient} indexName={indexName}>
                {latLng && (
                    <Configure
                        hitsPerPage={20}
                        clickAnalytics
                        getRankingInfo={true}
                        filters={`postalCodesServiced.postalCodesCovered.postalCode:${currentPostalCode}`}
                    />
                )}
                {!latLng && (
                    <Configure
                        hitsPerPage={20}
                        clickAnalytics
                        aroundLatLngViaIP={true}
                        getRankingInfo={true}
                        aroundRadius={50000}
                    />
                )}
                <section>
                    <SearchBox onPostalCode={setPostalCode} />
                    <ShowCategories />
                </section>
                <section>
                    <Results>
                        <div aria-live="polite">
                            <CustomHits
                                hitComp={hitComp}
                                hitsAsGrid={true}
                                postalCode={location}
                            />
                        </div>
                    </Results>
                </section>
            </InstantSearch>
        </Layout>
    )
}

const Results = connectStateResults(
    ({ searchState: searchState, searchResults: res, children }) =>
        searchState && searchState.query ? (
            res && res.nbHits > 0 ? (
                children
            ) : (
                <p>Your search did not return any results.</p>
            )
        ) : (
            <Header />
        )
)

const ShowCategories = connectStateResults(
    ({ searchState: searchState, searchResults: res, children }) =>
        searchState && searchState.query ? (
            <CustomMenu attribute="primaryServiceGroup.category.title" />
        ) : (
            <div></div>
        )
)

const CustomMenu = connectMenu(Categories)

const HitWithInsights =
    typeof window !== 'undefined' ? hitComps['HitComps'] : hitComps['HitComps']

const SearchHits = ({ hits, postalCode }) => {
    return (
        <>
            {hits.map((hit) => (
                <div key={`search-hit-${hit.objectID}`}>
                    <HitWithInsights
                        key={hit.objectID}
                        hit={hit}
                        postalCode={postalCode}
                    />
                </div>
            ))}
        </>
    )
}

const CustomHits = connectHits(SearchHits)

export default IndexPage
