import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import find from 'lodash/find'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Theme from '../shared/colors'

import { H2, P, Span, UL } from '../shared/typography'

const CategoriesList = () => {
    
    const data = useStaticQuery(graphql`
        {
            allContentfulCategory {
                edges {
                    node {
                        id
                        icon {
                            name
                            iconName
                            style
                        }
                        title
                    }
                }
            }
        }
    `)

    const categories = data.allContentfulCategory.edges
    
    return (
        <CategoryContainer>
            {categories && (
                <CategoryList>
                    {categories.map((item) => (
                        <Category key={`category-${item.node.id}`}>
                            <CategoryWrapper>
                                <CategoryIcon icon={['far', `${item.node.icon.iconName}`]} />   
                                <CategoriyTitle size='subtitle'>
                                    {item.node.title}{' '}
                                </CategoriyTitle>
                            </CategoryWrapper>
                        </Category>
                    ))}
                </CategoryList>
            )}
        </CategoryContainer>
    )
}

const CategoryContainer = styled.div`
    padding: 0;
`

const CategoryList = styled(UL)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    margin: 0;
    padding: 0;
    list-style: none;
`

const Category = styled.li`
    margin: 0;
    padding: var(--space-xs) var(--space-xs) 0 0;
    text-overflow: ellipsis;
    overflow: hidden;

`
const CategoryWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: var(--space-xs);
    height: 100%;
    border-width: 1px 1px 4px 1px;
    border-style: solid;
    border-color: ${(props) =>
        props.isRefined ? Theme.text.link : Theme.ui.secondary};
    border-radius: var(--space-3xs);
    text-overflow: ellipsis;
    color: ${(props) =>
        props.isRefined ? Theme.text.link : Theme.text.primary};
    overflow: hidden;
`

const CategoryIcon = styled(FontAwesomeIcon)`
    display: block;
    padding: 0 0 var(--space-xs) 0;
`

const CategoriyTitle = styled(P)`
    display: block;
    margin: 0;
    padding: 0;
    font-weight: bold;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
`

export default CategoriesList
