import * as React from 'react'
import styled from 'styled-components'

import { H1, P } from '../shared/typography'

import CategoriesList from '../../components/global/categories-list'

const Header = () => {
    return (
        <HeaderContent>
            <H1>Find Nearby Assistance</H1>
            <P>
                Start your search for local services like medical care, food,
                job training, and more.
            </P>
            <CategoriesList />
        </HeaderContent>
    )
}

const HeaderContent = styled.header`
    padding: var(--space-m);
`

export default Header
